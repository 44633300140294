import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
import Contactus from './pages/contact_us';
import Index from './pages/index';
import Capabilities from './pages/capabilities';
import Dowefit from './pages/dowefit';
import NoMatch from './pages/nomatch'; // 404 page
import DigiStart from './pages/digistart';
import DigiBrake from './pages/digibrake';
import MachineControl from './pages/machine-control'
import sunWalker from './pages/sunwalker';
import { createBrowserHistory as createHistory } from 'history';
import ReactGA from 'react-ga';
import { Roll } from 'react-reveal';

const history = createHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
})

export default class Navigation extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <>
        <Router history={history}>
          <Navbar bg="dark" variant="dark" expand="lg" className="headernavbar sticky-top">
            <Roll clear><Navbar.Brand href="/"><span className="topleftlogo"></span></Navbar.Brand></Roll>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link className="homeLink nav-link" to="/">HOME</Link>

                <NavDropdown title="PRODUCTS AND CAPABILITIES" id="basic-nav-dropdown">
                  <NavDropdown.Item className="nav-link" href="/capabilities">What we are capable of</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/digi-brake">DC Injection Brakes</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/digi-start">Electronic Soft Starters</NavDropdown.Item>
                  <NavDropdown.Item className="nav-link" href="/machine-control">Engenious Machine Control</NavDropdown.Item>
 { /*               <NavDropdown.Item className="nav-link" href="/sunwalker">Sunwalker Crosswalk System</NavDropdown.Item> */ }

                  {/*   <NavDropdown.Item className="nav-link" href="/crosswalk-flasher">Sunwalker Crosswalk Flasher</NavDropdown.Item>                
               */}
                </NavDropdown>

                <Link className="nav-link" to="/why-us">WHY US</Link>
                <Link className="nav-link contactUs" to="/contact-us/" ><div>CONTACT US</div></Link>
              </Nav>

            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route path="/" exact component={Index} />
            <Route path="/capabilities" component={Capabilities} />
            <Route path="/contact-us" component={Contactus} />
            <Route path="/why-us" component={Dowefit} />
            <Route path="/digi-start" component={DigiStart} />
            <Route path="/digi-brake" component={DigiBrake} />
            <Route path="/machine-control" component={MachineControl} />
           {/* <Route path="/sunwalker" component={sunWalker} /> */}
            <Route path="/404.html" component={NoMatch} />
            <Redirect to="/404.html" />

          </Switch>
        </Router>

      </>
    );
  }
}
