import React from 'react';
import {  Container, ListGroup, Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { Zoom } from 'react-reveal';
import YouTube from 'react-youtube';

class Capabilities extends React.Component {
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    render() {
    
    const opts = {height: '390', width: '640' };

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SOFTAC | Capabilities</title>
            <link rel="canonical" href="https://www.softac.com/capabilities" /> 
            <meta name="description" content="SOFTAC’s team of engineers, software developers and technicians make selecting us as an integrator an easy decision."/>
          
        </Helmet>
        
        <Container>
            <br/>
            <h1 className="header-highlight">Our Capabilities</h1>

SOFTAC’s team of engineers, software developers and technicians make selecting us as an integrator an easy decision. Over 50 years of experience in design, development and manufacturing makes us one of the longest standing businesses in our industry. Our diverse portfolio of projects over our tenure gives you the assurance that we are very capable of taking on your project.
<br/>
<br/>
<br/>

<YouTube className="youtube" containerClassName="youtubeholder" videoId="BQeZ9nJnNns" opts={opts} onReady={this._onReady} />

<br/>

<Zoom cascade>
<ListGroup>
        <div><h4>Work We Do On A Regular Basis</h4></div>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Custom controls design & packaging</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Ski lift or gondola drive systems</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Wastewater & water treatment kiosks and controls</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Most aspects of sawmill controls</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>MCC's & Power distribution systems</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Variable speed drive builds and harmonic distortion mitigation</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Marine controls for automated door systems and automated winches</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Mobile controls combining engines, transmissions and hydraulics</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Electronic soft starters and DC injection brakes</ListGroup.Item>

    </ListGroup>
</Zoom>
<br/><br/>

<Zoom cascade>

<ListGroup>
        <div><h4>Engineering and Design </h4></div>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Engineers</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Draftsman</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>PLC Programmers - competent in all major brands</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>HMI Programmers - competent in all major brand</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Proficient in use of various electrical CAD tools, specializing in WS CAD and Eplan</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Automated submittal process</ListGroup.Item>

</ListGroup>
</Zoom>

<br/><br/>
<Zoom cascade>

    <ListGroup>
        <div><h4>Software Development Skills</h4></div>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Linux & Multi threading</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>C, C++, Javscript, Python, JSON, PHP, Oauth2, NodeJS, API, IOT and webserver integration</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Hardware and software experience with I2C, SPI, GPIO and other device drivers</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Embedded Linux boot process</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>.NET (c# / ASP.NET)</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Visual Studio and Visual Studio Code</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>AutoCAD, AutoCAD electrical, MS Visio</ListGroup.Item>

    </ListGroup>
</Zoom>
<br/>


<Zoom cascade>
    <ListGroup>
        <div><h4>Our Facilities</h4></div>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>5,000 square feet centrally located in the industrial area of Chilliwack, British Columbia</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Centralized and dedicated office space for engineering, admin work, sales and staff lunchroom.
    </ListGroup.Item>

    </ListGroup>
<br/>
<br/>
</Zoom>


</Container>





</>

)

}
}

export default Capabilities;

