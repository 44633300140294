import React from "react";
import Digicontact from '../components/digicontact';
import { Container, Row, Col, ListGroup, Table} from "react-bootstrap";
import ModalOne from '../images/starter2.jpg';
import ModalTwo from '../images/starter4.jpg';
import ModalThree from '../images/starter_3.JPG';
import ModalImage from "react-modal-image";
import { Helmet } from 'react-helmet';

export default class extends React.Component {
    render() {
        return (
            <>
         {/*   <Digicontact source="Digi-Start" /> */}
         <Helmet>
                <meta charSet="utf-8" />
                <title>SOFTAC | Digital Soft Starters</title>
                <link rel="canonical" href="https://www.softac.com/digi-start" />      
                <meta name="description" content="SOFTAC’s digital starters are one of the worlds only true industrial grade starters that has proven itself with 25 years of history of never failing in some of the worlds toughest and most extreme applications. " />
            </Helmet>

                <Container className="digistartcontainer">

           <Row>
                <Col>
                <br/>
                <h1 className="header-highlight">Softac's DigiStart</h1>

                For over 25 years, SOFTAC starters have been starting electric motors anywhere from 5HP to 800HP across the globe.  
                SOFTAC prides itself in building the toughest Digital Solid State starter in the world.  
                SOFTAC’s Electronic Soft Starters is one of the worlds only true industrial grade starters that has proven itself with 25 years of 
                history of never failing in some of the worlds toughest and most extreme applications. 
            </Col>
        </Row>

<br/>
         <Row className="justify-content-md-center text-center imageRow">


        <Col  className="thumbholder" sm>     
        <ModalImage className="modalthumb" small={ModalOne} medium={ModalOne} alt="Starter Image" />
        </Col>

        <Col  className="thumbholder" sm>     
        <ModalImage className="modalthumb bumpdown rotate_ninety"  small={ModalThree} medium={ModalThree} alt="Starter Image" />
        </Col>

        <Col className="thumbholder" sm>     
        <ModalImage className="modalthumb" small={ModalTwo} medium={ModalTwo} alt="Starter Image" />
        </Col>



        </Row>
        <br/><br/>

        <Row><Col>
                The SOFTAC Solid State DigiStart provides reduced voltage, stepless starting of three phase AC induction motors. 
                Benefits of solid state starters compared to conventional motor starters include: <br/><br/>
                </Col></Row>

                <ListGroup>
                <ListGroup.Item className="cardstyles" style={{marginTop:  '10px'}}>Reduction of motor inrush current on startup</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop:  '10px'}}>A “soft start” to reduce stress on mechanical drive train components</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Extended motor life by reducing stress on motor windings</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Closer control & monitoring of starting and running functions</ListGroup.Item>
                </ListGroup>
                
                <br/><br/>

                <Row><Col>
                SOFTAC DigiStart contains a six SCR power section with two SCRs per phase connected inverse-parallel for variable AC output 
                voltage with minimal motor and starter heating.  The power section is capable of providing maximum torque per amp up to locked 
                rotor current during initial starting and throughout the motor’s speed torque curve.  A single logic control card, which is common 
                to all horsepower sizes, provides digital selection of the starter parameters including starting voltage (Torque), an adjustable 
                linear voltage ramp (Acceleration time), adjustable current limit, and a selectable acceleration kick, providing simple, reliable, 
                reduced voltage starting.

                </Col></Row>
<br/>
    

                <h4>Standard features:</h4>
                <Table striped bordered responsive variant="dark">
                <tbody>
               
                <tr>
                    <td>Single Microprocessor Logic control card for all HP sizes</td>
                    <td>Open Loop Voltage Control with adjustments for initial voltage 0% - 10%</td>
                </tr>

                <tr>
                <td>Ramp time to full voltage 0.5 - 60 seconds.  Kickstart boost 150% - 1000% current</td>
                <td>Torque 0.1 - 2 seconds</td>
                </tr>

                <tr>
                <td>Acceleration kick - selectable</td>
                <td>DV/DT Protection</td>
                </tr>

                <tr>
                <td>Phase Loss protection</td>
                <td>Stack Overtemperature protection</td>
                </tr>

                <tr>
                <td>Shorted SCR Indication</td>
                <td>Fully Electronic Overload, selectable Class 10, 20 or 30</td>
                </tr>

                <tr>
                <td>Auxiliary Contacts - 2 sets form C</td>
                <td>LCD Status Display</td>
                </tr>

                <tr>
                <td>RS232 Communication Port</td>
                <td>Up to speed indication & output</td>
                </tr>

                <tr>
                <td>Chassis or enclosed units</td>
                <td>Compact design for MCC installation</td>
                </tr>

                <tr>
                <td>Adjustable Ramp down for pumps & fan applications (See also DigiBrake)</td>
                <td>Overload bypass</td>
                </tr>

                <tr>
                <td>Auto/Manual Overload reset</td>
                <td>Energy saver mode</td>
                </tr>

                <tr>
                <td>Jog Enable</td>
                <td></td>
                </tr>

                </tbody>
               </Table>
            <br/>

                <h4>Specifications:</h4>

                <Table striped bordered responsive variant="dark">
                <tbody>
                    <tr>
                        <td>Power:</td><td>  208V - 600VAC 3 phase 45-65hz</td>
                    </tr>

                    <tr>
                        <td>Enclosure:</td><td>Optional - Nema 1, 12, 4, 4X</td>
                    </tr>

                    <tr>
                        <td>Ambient:</td><td> 0-40c at Sea Level</td>
                    </tr>

                    <tr>
                        <td>Overloads:</td><td>Fully electronic selectable Class 10, 20 or 30</td>
                    </tr>


                    <tr>
                        <td>Control Voltage:</td><td>24VDC or 120VAC</td>
                    </tr>
                
                    <tr>
                        <td>Power Section:</td><td>6 SCR</td></tr>
                    <tr>
                        <td>Cooling:</td><td>Natural convection or fan</td>
                    </tr>

                    <tr>
                        <td>Transient Voltage protection:</td><td>R.C. Snubbers</td>
                    </tr>

                    <tr>
                        <td>Power Rating:</td><td>20 times FLA, 1 cycle, 500% motor current for 30 seconds, 125% continuous</td>
                    </tr>

                    <tr>
                        <td>Braking:</td><td>Option to add DigiBrake to DigiStart</td>
                    </tr>
                    
                    <tr>
                        <td>Display:</td><td>Remote keypad, LCD</td>
                    </tr>

                </tbody>
                </Table>            
            
            <br/>



<br/>
</Container>


            </>            
        )

    }

}