import React from "react";
import { Container, Row, Col, ListGroup, Table } from "react-bootstrap";
import Digicontact from '../components/digicontact';

import ModalOne from '../images/starter1.jpg';
import ModalThree from '../images/starter3.jpg';
import ModalTwo from '../images/brake3.jpg';

import ModalImage from "react-modal-image";
import { Helmet } from 'react-helmet';



export default class extends React.Component {

    render() {

        return (
            <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>SOFTAC | Digi Brake</title>
                <link rel="canonical" href="https://www.softac.com/digi-brake" />      
                <meta name="description" content="SOFTAC prides itself in building the toughest DC Injection brake in the world." />
     
            </Helmet>



            <Container>
           <Row>
                <Col>
                <br/>
                <h1 className="header-highlight">Softac's Digi-Brake</h1>
                For over 25 years, SOFTAC DC Injection Brakes have been stopping electric motors anywhere from 
                5HP to 800HP across the globe.  SOFTAC prides itself in building the toughest DC Injection 
                brake in the world.  SOFTAC’s Digigtal Brakes are one of the worlds only true industrial grade 
                injection brakes that has proven itself with 25 years of history of never failing in some 
                of the worlds toughest and most extreme applications. <br/>
                <br/>
                Our standalone brakes can be added to existing systems utilizing any brand of Soft starter, or across 
                the line starters. <br/>
                <br/>
                Our brakes are particularly valuable in high inertial loads such as saws, chippers, hogs, and rock crushers. 
                <br/>
                <br/>

</Col></Row>


<Row className="justify-content-md-center imageRow">

<Col md="3" className="thumbholder">     
<ModalImage className="modalthumb" small={ModalOne} medium={ModalOne} alt="Brake Image 1" />
</Col>


<Col md={{span: 3}} className="thumbholder">     
<ModalImage className="modalthumb" small={ModalTwo} medium={ModalTwo} alt="Brake Image 3" />
</Col>

<Col md={{span: 3}} className="thumbholder">     
<ModalImage className="modalthumb" small={ModalThree} medium={ModalThree} alt="Brake Image 2" />
</Col>

</Row>

<Row>
<Col>
<br/><br/>
                <h4>Standard features:</h4>

                <ListGroup>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Single Microprocessor Logic control card for all HP sizes</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>LCD Status Display</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Password protection</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Adjustable brake current 0-300% motor FLA</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Brake Time 0-240 seconds</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Internal & External Zero speed detection</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Adjustable Zero Hold 0-5 seconds</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Adjustable Cycle time 0-60 minutes</ListGroup.Item>
                <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Relay outputs for brake on, zero speed, cycle time, feedback loss, line frequency, brake ready, and device overtemperature</ListGroup.Item>

                </ListGroup>
                
  <br/>   <br/> 


                <h4>Specifications:</h4> <br/>

                <Table striped bordered responsive variant="dark">
                <tbody>
                    <tr>
                        <td>Power:</td><td>  208V - 600VAC 3 phase 45-65hz</td>
                    </tr>

                    <tr>
                        <td>Enclosure:</td><td>Optional - Nema 1, 12, 4, 4X</td>
                    </tr>

                    <tr>
                        <td>Ambient:</td><td> 0-40c at Sea Level</td>
                    </tr>

                    <tr>
                        <td>Overloads:</td><td>Fully electronic selectable Class 10, 20 or 30</td>
                    </tr>


                    <tr>
                        <td>Control Voltage:</td><td>24VDC or 120VAC</td>
                    </tr>
                
                    <tr>
                        <td>Power Section:</td><td>4 SCR</td></tr>
                    <tr>
                        <td>Cooling:</td><td>Natural convection or fan</td>
                    </tr>

                    <tr>
                        <td>Transient Voltage protection:</td><td>R.C. Snubbers</td>
                    </tr>

                    <tr>
                        <td>Power Rating:</td><td>3 times FLA</td>
                    </tr>

                    <tr>
                        <td>Display:</td><td>Remote Keypad, LCD</td>
                    </tr>

                </tbody>
                </Table>            
            
            <br/>
            
        </Col></Row>




<br/>

<br/>

        </Container>
            </>            
        )

    }

}