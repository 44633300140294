import React from 'react';
import MyCarousel from '../components/carousel';  // top carousel

import { Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Zoom } from 'react-reveal';

import Carousel from 'react-multi-carousel';  // project image carousel
import 'react-multi-carousel/lib/styles.css';

import FiftyYears from './content/fiftyyears';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class Index extends React.Component {
    constructor(e) {
        super(e);
        this.state = { isLoaded: false };
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    render() {

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SOFTAC | Home</title>
                    <link rel="canonical" href="https://www.softac.com/" />
                </Helmet>

                <Container fluid={true} className="fullHeight">
                    <Row>
                        <Col xl={true} className="slideheight">
                            <MyCarousel />
                        </Col>
                    </Row>
                </Container>


                <div className="main_content topBlock oldphoto1">
                    <Container flex="true"   >
                        <Row className="justify-content-md-center text-center">
                            <FiftyYears />
                        </Row>

                        <br />

                    </Container>
                </div>

                <br /><br />
                <div className="main_content midBlock oldphoto2">
                    <Container flex="true">

                        <Row className="justify-content-md-center text-center">
                            <Col md={{ span: 12 }} >

                                <Zoom fraction={.75}>
                                    <div style={{ background: "rgba(10,90,180,.8)", 'border-radius': '5px' }}>

                                        <article>
                                            <h2 style={{ textAlign: "center" }}><br />QUOTE TO WIN</h2><br />

                                            We approach every opportunity as though we will be the vendor of choice. All of our quotes
                                            are prepared with actual part numbers and material specifications, and as a result our quotes are
                                            accurate on price and requirements to provide a foundation for our team to be ready to
                                            work. We apply decades of industry experience to each project to ensure our customers get
                                            a competitive quote that meets or exceeds their expectations.
                                            <br /><br />
                                        </article>
                                    </div>
                                </Zoom>
                            </Col>
                        </Row>


                    </Container>
                </div>

                <div className="main_content lowBlock">

                    <h2>RECENT PROJECTS</h2><br /><br />
                    <Carousel responsive={responsive}
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={2500}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                    >


                        <div className="project_slider"><img src='/images/project_images/9DBCE0D8-77A5-47A2-9B19-139EE7BADD79.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/00100lPORTRAIT_00100_BURST20190221083437768_COVER.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/00100lPORTRAIT_00100_BURST20190304094807876_COVER.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/00100trPORTRAIT_00100_BURST20200605110306815_COVER.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/451A3277-3607-45D1-BDCD-63BCE1E3E088.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/2851DEAC-22D4-444A-A778-17C2316C62A1.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/20181113_082503.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/20190214_112955.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/20190214_113138.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/20190503_073557.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/20190509_132628.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/B80BBEEE-B89F-4495-B9A4-B52ECBF1AFB4.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/E90372EE-EB78-47EA-A29C-CBD90F7B2FA4.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_0006.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_1368.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_1975.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_1986.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_1990.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_2204.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_2213.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_2990.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_3097.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_3098.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_3784.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_3789.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4237.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4404.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4406.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4410.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4618.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4869.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_4962.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_5255.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_5373.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_5598.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_5608.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_5620.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_6406.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_6587.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_6812.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_6922.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_6932.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7105.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7138.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7335.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7336.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7420.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7906.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7907.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_7916.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_8015.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_8019.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180327_121416.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180615_085002.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180622_101130.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180622_102158.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180622_102246.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180711_150349.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180727_134617.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20180824_105041.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20181009_085502.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20181012_103925.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20181018_094955.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20181113_082411.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20181113_082451.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190121_072452.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190304_094659.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190304_094723.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190321_161715.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190321_162112.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190322_134443.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190322_134657.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190325_081242.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190325_081325.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190325_081346.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190325_081415.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190409_141246.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20190429_094909.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20200511_144556.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20200511_144617.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20200527_074018.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20200605_110051.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/IMG_20200605_110124.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/PXL_20210121_170000770.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/PXL_20210121_170247013.jpg' /></div>
                        <div className="project_slider"><img src='/images/project_images/PXL_20210126_194519553.jpg' /></div>
                    </Carousel>
                </div>
            </>
        )
    }
}



