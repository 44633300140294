import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import CrapSpreader1 from '../images/crosswalk1_lores.jpg';
import CrapSpreader2 from '../images/crosswalk2_lores.jpg';
import CrapSpreader3 from '../images/crosswalk3_lores.jpg';

class sunWalker extends React.Component {

    render() {

    return (
        <>
      <Helmet>
            <meta charSet="utf-8" />
            <title>SOFTAC | Sunwalker Crosswalk System</title>
            <link rel="canonical" href="https://www.softac.com/machine-control" /> 
            <meta name="description" content="SunWalker Crosswalk system." />
      </Helmet>

        <br/>
        <Container>
        <h1 className="header-highlight">Sunwalker Crosswalk System</h1>
        <Row className="justify-content-md-center text-left">
            <Col md="12">
                <h2>Summary</h2>    <br/>        
                <img src={CrapSpreader1} alt="Solar Crosswalk System" style={{float: "right", width: "38%", "padding-left": "3%"}} />
                Softac has developed a Solar powered road crossing system that is completely wireless.  Simply place one of our 
                systems on either side of the road, and at the press of a button, you will have flashing lights on both sides
                 of the road to safely notify traffic that a road crossing is happening.  The duration of the flashing lights 
                 is set by an adjustable timer and simply shuts off once the timer has ended. <br/><br/>
                
                Customers can choose from a variety of methods of activating the lights, ranging from panel mounted push buttons, 
                to pole mounted rope pull switches, or even handheld standalone push buttons that can be mounted on any equipment. 
                All our activation devices are wireless and batteryless requiring zero maintenance!<br/><br/>

        <ListGroup>        

        <div><h4>Applications and Uses:</h4></div>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px', width: "60"}}>Large plants or warehouses that have high indoor traffic crossings and need to notify people of an approaching forklift</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Locations that operate on both sides of the street, for safe crossing of motorized vehicles, pedestrians</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Softac utilizes 12V AGM batteries in the controller for long life expectancy and no maintenance</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Wireless controls can be programmed to operate specific lights as groupingsor individually</ListGroup.Item>
        <ListGroup.Item className="cardstyles" style={{marginTop: '10px'}}>Default range is 40M (130ft) and can be extended with additional antennas</ListGroup.Item>
        

        </ListGroup>
            
            <br/>


        <br/>
        <div style={{"text-align" : "center"}}>
        <img src={CrapSpreader3} alt="Solar Crosswalk System" style={{"text-align": "center", width: "50%", "padding": "3%"}} />
        <img src={CrapSpreader2} alt="Solar Crosswalk System" style={{float: "center", width: "50%", "padding": "3%"}} />
        </div>
        </Col></Row>
        </Container>
        <br/>

        </>

        )
    }
}

export default sunWalker;
