import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ContactForm from '../components/contactform';
import { Helmet } from 'react-helmet';

function Contactus() {

    const mapStyle = {
        "display": "flex",
        "width" : "100%",
        "height": "400px",
        "position" : "relative"
    }

    return ( 
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>SOFTAC | Contact Us</title>
        <link rel="canonical" href="https://www.softac.com/contact-us" />     
        <meta name="description" content="Contact Softac today and discover the value we can add to your projects." />
    </Helmet>

        <Container>

            <br/>
        <Row className="justify-content-md-center text-center">
        <Col md="12">
                <h1 className="header-highlight">Contact Softac today</h1>
        </Col>
        <br/>
            <Col md={{span : 5}}>        
                <ContactForm />
            </Col>

            <Col md={{span: 5, offset: 1}}>
                <h2>How to Reach Us</h2>
                        <span >
                        SOFTAC Systems LTD.<br/>
                        Unit 101 - 8071 Aitken Road<br/>
                        Chilliwack, British Columbia V2R 4H5<br/>
                        Telephone: 604-888-9507<br/>
                        </span>
                        <br/>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d792.8638183760186!2d-121.99747198747795!3d49.148960922761106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d15cb7e53f15%3A0x3e413752876eb5f3!2sSoftac%20Systems%20Ltd!5e0!3m2!1sen!2sca!4v1672764767519!5m2!1sen!2sca" style={{mapStyle}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <br/>
            </Col>            
        </Row>
        </Container>
        </>
    );
}

export default Contactus;