import React from 'react';
import './App.css';
import Navigation from './navigation.js';
import Footerband from './components/footerband';
//import { loadReCaptcha } from 'react-recaptcha-v3';
import "core-js/stable";
import "regenerator-runtime/runtime";

class App extends React.Component {
  componentDidMount() {
   // loadReCaptcha('6LeGjq4UAAAAALlU_xjD6E4TRawt-PAwQQJAlk20');
  }

  render() {
    return (
      <>
        <Navigation />
        <Footerband />
        </>

    );
  }
}

export default App;
