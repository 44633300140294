import React from 'react';
import { Row, Col, CardDeck, Container, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Zoom } from 'react-reveal';

class Dowefit extends React.Component {

    render() {
        const liststyles = { borderRadius : '5px', fontSize : '25px', backgroundColor : ' rgba(16,118,188,.8', color : '#fff', padding : '40px 0 40px 0', margin : '15px 0 15px 0 '};

    return (
        <>
      <Helmet>
            <meta charSet="utf-8" />
            <title>SOFTAC | Why Us</title>
            <link rel="canonical" href="https://www.softac.com/why-us" /> 
            <meta name="description" content=" We’ll work closely with you to clearly understand your needs and ensure there is alignment between us as two organizations working together." />
      </Helmet>

        <br/
        >
        <Container>

        <Zoom cascade>
        <ListGroup>
            <Row className="justify-content-md-center text-center">
                <Col md="12">
                <h1 className="header-highlight">Why choose to work with Softac?</h1>
                <br/>
                <CardDeck>
                <Card className="cardstyles">
                    <Card.Title><h4>Collaborate</h4></Card.Title>
                    <Card.Body>

    Challenge us!  We love a good challenge, but on the same token we know our strengths & weaknesses and know when to bow out and when to take on a new challenge.  
    </Card.Body>
                </Card>

                <Card className="cardstyles">
                <Card.Title><h4>Align</h4></Card.Title>
                <Card.Body>
           
                We’ll work closely with you to clearly understand your needs and ensure there is alignment between us as two organizations working together, but also to ensure our design meets the intent prior to starting work.
                </Card.Body>
            </Card>


            <Card className="cardstyles">
                <Card.Title><h4>Deliver</h4></Card.Title>
                <Card.Body>
            
                Whether it’s engineering services or a combination of design and manufacturing, our processes are transparent and we will deliver on what we promise.
                </Card.Body>
            </Card>
            </CardDeck>

            </Col>
            </Row>
            </ListGroup>
            </Zoom>

<Zoom>


            <Row className="justify-content-md-center text-center">
                <Col style={{paddingTop : '30px'}}>
                    <h4>SOFTAC'S GUIDING PRINCIPLES</h4>
                <ListGroup>
                    <ListGroup.Item style={liststyles}>
                    We will ALWAYS conduct our business to the highest degree of morals and ethics
                    </ListGroup.Item>

                    <ListGroup.Item style={liststyles}>
                    We will NEVER sacrifice on quality
                </ListGroup.Item>

                <ListGroup.Item style={liststyles}>
                    We will ALWAYS be easy to do business with

                    </ListGroup.Item>
                </ListGroup>
                </Col>
            </Row>
            </Zoom>

            <Zoom cascade>

            <Row className="justify-content-md-center text-center">
                  <Col style={{paddingTop : '30px'}}>
                      <h4>TESTIMONIALS</h4><br/>
                  <span style={{fontStyle:"italic"}}>I commissioned the new Fishtrap Creek Mitigation Well site today in Abbotsford, and everything went great! I have to say that you guys did a terrific job on that kiosk, and the panel work is the best I’ve seen. Congratulations! – Paul Burke, Lakeside Automation Inc</span>
            </Col></Row>

            <Row className="justify-content-md-center text-center">
                  <Col style={{paddingTop : '30px'}}>
                  <span style={{fontStyle:"italic"}}>A few days ago Mike Madrigga Vice President of Northwood passed away. Mike and I were responsible for the decision to purchase the first laser scanners developed by Softac for the 24 foot lumber mill in Houston B.C. owned by Northwood Pulp and Timber. It was a gut wrenching decision as we weren’t purchasing one system, we were purchasing three systems for the board edgers, to be delivered and installed over three successive long weekends in the fall of 1987. The sawmill producing in excess of a million board feet of lumber a day on a 2 shift basis. We were putting all our trust in Warren Thomlinson, John Wong (developer) and all the other technical people at Softac. I was in charge of the project for Northwood and had the scanners failed to deliver I would have been out of a job. During startup there were many days in excess of 20 hours but the Softac team delivered exactly what they said they would. Those scanners and optimizing programmes were the first of a kind in the lumber industry and became the “me too product” for many existing software companies in B.C., Quebec and the western United States. There is no doubt Softac stands tall in wood industry products and in the other industry’s you serve. Softac has made a difference!</span>

            </Col></Row>            
            </Zoom>
        </Container>
<br/>

        </>

        )
    }
}

export default Dowefit;
