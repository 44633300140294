import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Oops from '../images/oops.jpg';
import { Helmet } from 'react-helmet';

export default class NoMatch extends React.Component {
    render() {

        return (
              <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SOFTAC | 404 Page not found</title>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>

                <Container >
                <Row className="justify-content-md-center" style={{'min-height': "50vh"}} >
                    <Col md="auto">
                    <br/><br/>
                    It seem's you have landed on a page that does not exist.<br/><br/>
                    <img src={Oops} alt="Webpage not found."></img>
                    </Col>
                </Row>
                </Container>
            </>
        )
    }
}
