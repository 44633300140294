import React from 'react';
import { Modal, Button, Container, Row, Col, Spinner, Form } from 'react-bootstrap';
import axios from "axios";

export default class Digicontact extends React.Component {
    constructor(e) {
        super(e);
        this.state = {
            source: this.props.source,
            isLoaded: false,
            formReduced: false,
            postdata: { title: "Starter and Brake Contact Form", product: null, voltage: null, HP: null, FLA: null, mounting: null, name: null, email: null, phone: null }
        };
    }


    handleChange = (event) => {
        const { postdata } = this.state;
        const target = event.target.name;
        postdata[target] = event.target.value;
        this.setState({ postdata: postdata });
    }

    setToken = (v) => {
        this.setState({ google_verified: v });
    }

    confirmToken(response) {
        if (response.success) {
            this.setToken(true);
        }
    }

    verifyCallback = (recaptchaToken) => {
        if (!this.state.production) {
            axios.post('https://www.google.com/recaptcha/api/siteverify?6LeGjq4UAAAAAJa22UlnQy7O0rKyFbvSqakG6ji_&response=' + recaptchaToken, {})
                .then((r) => { this.confirmToken(r) })
                .then(() => { this.setToken(false) });
        } else {
            this.confirmToken(this.state.falseAuth);
        }
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    reduceForm = () => {
        this.setState({ formReduced: true });
    }

    expandForm = () => {
        this.setState({ formReduced: false });
    }


    handleSubmit = (event) => {
        event.preventDefault();

        var fail = false;
        Object.keys(this.state.postdata).each((title) => {
            if (this.state.postdata[title] === '') {
                fail = true;
            }
        });

        if (fail)

            alert("Please fill out all of the fields.");
        else { // submit the form
            axios.post('php_scripts/contact_email.php', this.state.postdata)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

            alert("Thank you for contacting us.");
            this.setState({ setShow: false });
        }
    }

    fullForm = () => {
        const noscroll = { "overflowY": "auto" };

        if (!this.state.formReduced) {
            return (
                <>
                    Click <button className="digibutton" onClick={this.reduceForm}>here</button> to provide email and phone only.<br /><br />

                    <Form.Label>What are you looking for?</Form.Label>
                    <Form.Control as="select" onBlur={this.handleChange} name="product" Size={3} style={noscroll} onChange={this.handleChange}  >
                        <option selected value="" style={{ display: "none" }}></option>

                        <option value="Digi-Start">Digi-Start</option>
                        <option value="Digi-Brake">Digi-Brake</option>
                        <option value="combination">Combination Starter and Brake</option>
                    </Form.Control>
                    <br />

                    <Form.Label>What voltage to you need?</Form.Label>
                    <Form.Control as="select" onBlur={this.handleChange} name="voltage" Size={5} style={noscroll} onChange={this.handleChange}  >
                        <option selected value="" style={{ display: "none" }}></option>
                        <option value="208v">208v 3PH 60Hz</option>
                        <option value="380v">380v 3PH 50Hz</option>
                        <option value="415v">415v 3PH 50Hz</option>
                        <option value="480v">480v 3PH 60Hz</option>
                        <option value="575v">575v 3PH 60Hz</option>

                    </Form.Control><br />


                    <Form.Label>Horse Power</Form.Label>
                    <Form.Control required type="number" name="HP" step=".1" onChange={this.handleChange} />
                    <br />
                    <Form.Label>Full Load Amperage(FLA)</Form.Label>
                    <Form.Control required type="number" name="FLA" step=".1" onChange={this.handleChange} />
                    <Form.Label>Chassis Mount or Enclosure</Form.Label>
                    <Form.Control as="select" name="mounting" Size={2} style={noscroll} onChange={this.handleChange}  >
                        <option selected value="" style={{ display: "none" }}></option>

                        <option value="chassis-mount">Chassis Mount</option>
                        <option value="enclosure">Enclosure</option>
                    </Form.Control>
                </>

            )
        } else return (<>
            Click <button className="digibutton" onClick={this.expandForm}>here</button> to provide more information for a detailed quote.<br /><br />
        </>
        )
    }

    showModal = (props) => {
        //const [show, setShow] = React.useState(false);
        const handleClose = () => this.setState({ show: false });
        const handleShow = () => this.setState({ show: true });

        //console.log(this.state.show);

        return (<>
            <Modal show={this.state.show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton onClick={handleClose}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ "font-size": "20px" }}>
                        Help us give an accurate quote by giving us some important information.
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form onSubmit={this.handleSubmit} className="digiform">
                        <Form.Group controlId="digi-quote">

                            <this.fullForm />

                            <br />
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" onBlur={this.handleChange} placeholder="Your Name" controlId={0} />

                            <br />
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" required onBlur={this.handleChange} placeholder="Enter email" id={2} />

                            <br />
                            <Form.Label>Contact phone number</Form.Label>

                            <Form.Control type="telephone" name="phone" onBlur={this.handleChange} placeholder="Your telephone" id={3} />

                            <br />

                            <Button variant="primary" style={{ left: "42%", position: "relative" }} type="submit" >
                                Contact Us
                            </Button>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <button className="digicontact" onClick={handleShow}>REQUEST A QUOTE NOW</button>

        </>
        )
    }


    render() {
        if (this.state.isLoaded) return (
            <>
                <this.showModal show={this.state.show} />
            </>
        )

        else return (
            <Container>
                <Row className="justify-content-md-center text-center" style={{ height: '45vh' }}>
                    <Col style={{ top: "40%", position: "relative" }}>
                        <Spinner animation="border" role="status" >
                        </Spinner>
                        <br /><br />
                        Loading...
                    </Col>
                </Row>
                <br />
            </Container>
        )
    }
}